
import Autocomplete from "@evercam/shared/components/Autocomplete"
import { mapStores } from "pinia"
import { useShareStore } from "@/stores/shares"
import { useAccountStore } from "@/stores/account"
import { camelizeKeys } from "humps"

export default {
  components: {
    Autocomplete,
  },
  computed: {
    ...mapStores(useShareStore, useAccountStore),
    routeCameraExid() {
      return camelizeKeys(this.$route.params)?.cameraExid
    },
  },
}
