
import { mapStores } from "pinia"
import { useShareStore } from "@/stores/shares"
import { useCameraStore } from "@/stores/camera"
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import { ShareVisibility } from "@evercam/shared/types"
import { camelizeKeys } from "humps"
export default {
  components: {
    CopyToClipboardBtn,
  },
  props: {
    cameraUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isPublic: false,
      isDiscoverable: false,
      isPrivate: false,
    }
  },
  computed: {
    ...mapStores(useShareStore, useCameraStore),
    shareVisibility() {
      if (this.isPrivate) {
        return ShareVisibility.Private
      }

      return this.isPublic && this.isDiscoverable
        ? ShareVisibility.PublicDiscoverable
        : ShareVisibility.PublicUndiscoverable
    },
    isShareVisibilityPrivate() {
      return this.shareStore.visibility === ShareVisibility.Private
    },
    routeCameraExid() {
      return camelizeKeys(this.$route.params)?.cameraExid
    },
  },
  watch: {
    isPublic(val) {
      if (!val) {
        this.isDiscoverable = false
      }
      this.isPrivate = !val && !this.isDiscoverable
      this.updateVisibility()
    },
    isDiscoverable(val) {
      if (val) {
        this.isPublic = true
      }
      this.isPrivate = !val && !this.isPublic
      this.updateVisibility()
    },
    isPrivate(val) {
      if (val) {
        this.isPublic = false
        this.isDiscoverable = false
      } else {
        this.isPublic = true
      }
      this.updateVisibility()
    },
  },
  mounted() {
    this.defaultVisibilityValues()
    this.updateVisibility()
  },
  methods: {
    defaultVisibilityValues() {
      this.isPublic = this.cameraStore.selectedCamera?.isPublic
      this.isDiscoverable =
        this.cameraStore.selectedCamera?.discoverable && this.isPublic
      this.isPrivate = !this.isPublic
    },
    updateVisibility() {
      this.shareStore.visibility = this.shareVisibility
    },
    cancelVisibilityDialog() {
      this.shareStore.visibilityDialog = false
      this.defaultVisibilityValues()
    },
  },
}
