import { render, staticRenderFns } from "./shares.vue?vue&type=template&id=1aa84007&scoped=true"
import script from "./shares.vue?vue&type=script&lang=ts"
export * from "./shares.vue?vue&type=script&lang=ts"
import style0 from "./shares.vue?vue&type=style&index=0&id=1aa84007&prod&lang=css"
import style1 from "./shares.vue?vue&type=style&index=1&id=1aa84007&prod&scoped=true&lang=css"
import style2 from "./shares.vue?vue&type=style&index=2&id=1aa84007&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aa84007",
  null
  
)

export default component.exports